import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { useEffect, useState } from "react";

const IndexPage = ({ data }) => {
  const fixedText = "";
  const whenNotFixed = "";
  const [headerText, setHeaderText] = useState(whenNotFixed);

  useEffect(() => {
    const header = document.getElementById("myHeader");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        if (headerText !== fixedText) {
          setHeaderText(fixedText);
        }
      } else {
        header.classList.remove("sticky");
        if (headerText !== whenNotFixed) {
          setHeaderText(whenNotFixed);
        }
      }
    });
    window.removeEventListener("scroll", scrollCallBack);    
  }, [headerText]);

  const edges = data.allMarkdownRemark.nodes
  
  const events = edges.map(obj => (
    <li key={ obj.id } data-category="Behavioural Attitudes" className="behavioural">
      <a href={ obj.fields.slug } className="group ">
      <div className="symbol">&#9678;<span className="small-symbol"></span></div>
        <div className="title">
          <span className="programme-title">{ obj.frontmatter.title }</span>
        </div>
        <div className="location">{obj.frontmatter.place ? obj.frontmatter.place : '-'}</div>
        <div className="date">{obj.frontmatter.date ? obj.frontmatter.date : "~"}</div>
        <div className="years-ago">{obj.frontmatter.x ? obj.frontmatter.x + " tahun lalu" : "~"}</div>
      </a>
    </li>
  ))

	return (
		<Layout>
      <SEO title="Home" />
      <header className="row titles" id="myHeader">
        <div className="symbol">&nbsp;</div>
        <div className="title">Peristiwa</div>
        <div className="location">Lokasi</div>
        <div className="date up"><a href="#test">Tahun</a></div>
        <div className="years-ago">&nbsp;</div>
      </header>

      <ul className="row programme-list">
      { events }
      </ul>
      
		</Layout>
	);
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: {order: ASC, fields: frontmatter___order}) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date
          period
          title
          type
          x
          place
        }
      }
    }
  }
`